import React, { useState } from 'react';
import classNames from 'classnames';
import { useTransition, animated } from 'react-spring';
import { graphql, useStaticQuery } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import useInterval from '../helpers/useInterval';
import Arrow from '../../svg/arrow.svg';
import Play from '../../svg/play.svg';
import Pause from '../../svg/pause.svg';

const RenderServices = () => {
  const [currentService, setCurrentService] = useState(0);
  const [hover, setHover] = useState(false);
  const data = useStaticQuery(query);
  const services = data.services.edges.map(item => item.node);

  useInterval(() => next(), hover ? null : 3000);

  const transitions = useTransition(currentService, p => p, {
    from: { display: `none`, transform: `translateY(100%)` },
    enter: [{ display: `flex` }, { transform: `translateY(0)` }],
    leave: { display: `none`, transform: `translateY(-50%)` },
  });

  const renderServices = services => {
    return transitions.map(({ item, props, key }) => {
      const service = services[item];
      return (
        <animated.li key={key} style={props} className="RenderServices__item">
          <article className="RenderServices__article">
            <span className="RenderServices__number">{item + 1}</span>
            <h3 className="RenderServices__title">{service.title}</h3>
            <AniLink
              cover
              direction="right"
              bg="#1d55a2"
              className="btn btn-primary"
              to={`/${service.slug}`}
            >
              Découvrir
            </AniLink>
          </article>
        </animated.li>
      );
    });
  };

  const renderBullets = services => {
    return services.map((service, index) => (
      <li
        key={index}
        className={classNames({ active: currentService === index })}
        onClick={() => setCurrentService(index)}
      >
        {index + 1}
      </li>
    ));
  };

  const back = () => {
    if (currentService === 0) {
      setCurrentService(services.length - 1);
      return;
    }
    setCurrentService((currentService + services.length - 1) % services.length);
  };

  const next = () => {
    if (currentService === services.length - 1) {
      setCurrentService(0);
      return;
    }
    setCurrentService((currentService + 1) % services.length);
  };

  return (
    <section
      className="RenderServices"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onTouchStart={() => setHover(!hover)}
    >
      <ul className="RenderServices__list">{renderServices(services)}</ul>
      <div className="RenderServices__navigation">
        <Arrow className="top" onClick={() => back()} />
        <Arrow className="bottom" onClick={() => next()} />
      </div>
      <div className="RenderServices__pagination">
        <ul>{renderBullets(services)}</ul>
      </div>
      <div className="RenderServices__command">
        {hover ? <Pause /> : <Play />}
      </div>
    </section>
  );
};

const query = graphql`
  {
    services: allContentfulServices(sort: { fields: [order], order: ASC }) {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`;

export default RenderServices;
