import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { Element, scroller } from 'react-scroll';
import { graphql } from 'gatsby';

import RenderServices from '../components/index/RenderServices';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Arrow from '../svg/arrow.svg';

const IndexPage = ({ data }) => {
  const [toggle, setToggle] = useState(true);
  const props = useSpring({
    transform: toggle ? `translate(-50%, -20px)` : `translate(-50%, 0)`,
    opacity: 1,
    from: { opacity: 0 },
    onRest: () => {
      setToggle(!toggle);
    },
    config: {
      mass: 50,
      tension: 250,
      friction: 150,
      clamp: true,
    },
  });

  const fadeIn = useSpring({
    from: { opacity: 0 },
    opacity: 1,
    delay: 500,
  });

  return (
    <Layout>
      <SEO
        title="Service juridique complet"
        keywords={[`gatsby`, `application`, `react`]}
      />
      <section className="Index">
        <section
          className="Index__left"
          style={{
            background: `url(${data.background.fluid.src}), rgba(0, 0, 0, .5)`,
            backgroundRepeat: `no-repeat`,
            backgroundPosition: `center center`,
            backgroundSize: `cover`,
          }}
        >
          <animated.div className="Index__left__content" style={fadeIn}>
            <h1>Legal plan</h1>
            <h2>
              <span>Legal Plan</span> vous accompagne en vous proposant un
              service juridique complet
            </h2>
            <button
              className="btn btn-white"
              onClick={() =>
                scroller.scrollTo(`services`, {
                  smooth: `easeInOutQuint`,
                })
              }
            >
              Découvrir
            </button>
          </animated.div>
          <animated.div
            className="Index__down"
            style={props}
            onClick={() =>
              scroller.scrollTo(`services`, {
                smooth: `easeInOutQuint`,
              })
            }
          >
            <Arrow />
          </animated.div>
        </section>
        <Element name="services" className="Index__right">
          <RenderServices />
        </Element>
      </section>
    </Layout>
  );
};

export const query = graphql`
  {
    background: contentfulAsset(title: { eq: "background" }) {
      fluid {
        ...GatsbyContentfulFluid_tracedSVG
      }
    }
  }
`;

export default IndexPage;
